import { getConfig } from '@cian/config/browser';
import { getGlobalEventsLogs } from '@cian/events-log';
import { getHttpApi } from '@cian/http-api/browser';
import { getLogger } from '@cian/logger/browser';
import { registry } from '@cian/mf-registry/browser';
import { getTelemetry } from '@cian/telemetry/browser';

import { IApplicationContext, IApplicationContextCustom } from '../../../shared/types';

export const createContext = (): IApplicationContext => {
  const config = getConfig();

  return {
    config,
    logger: getLogger(),
    httpApi: getHttpApi(),
    telemetry: getTelemetry(),
    globalEventsLogs: getGlobalEventsLogs(),
    custom: config.getStrict<IApplicationContextCustom>('applicationContext.custom'),
    microfrontendsRegistry: registry(),
  };
};
