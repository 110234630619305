import * as React from 'react';

import * as styles from './LabelValueList.css';
import { ILabelValueItem } from '../../types/labelValue';

export interface ILabelValueListProps {
  items: ILabelValueItem[];
}

export const LabelValueList: React.FunctionComponent<ILabelValueListProps> = ({ items }) => (
  <ul className={styles['container']}>
    {items.map(({ label, value }) => (
      <li key={label} className={styles['item-wrapper']}>
        <div className={styles['label']}>{label}</div>
        <div className={styles['value']}>{value}</div>
      </li>
    ))}
  </ul>
);
