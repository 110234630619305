import { IApplicationContext } from '../../../shared/types/application';
import { IOpenCardRequestProps } from '../../types/openCardRequest';

export async function load(context: IApplicationContext) {
  const { runApp } = await import('../runApp');
  const mdl = await runApp(context);

  return mdl.default;
}

export function lazyLoad(context: IApplicationContext) {
  return async (props: IOpenCardRequestProps) => {
    const { runAppLazy } = await import('../runApp');
    const mdl = await runAppLazy(context, props);

    return mdl.default;
  };
}
