/**
 * Этот сервис тут, а не на страницах, потому что пока не понятно как его загрузить вместе с карточками
 * Мешает FAIL-653
 */

import { IApplicationContext } from '../../../../Card/CardEntry/shared/types/application';
import { loadFragmentAPI as loadComplaintsFragmentAPI } from '../../../../fragments/Complaints/browser';
import { loadFragmentAPI as loadFavoritesFragmentAPI } from '../../../../fragments/Favorites/browser';
import { loadFragmentAPI as loadNotesFragmentApi } from '../../../../fragments/Notes/browser';
import { loadFragmentAPI as loadNotificationsFragmentAPI } from '../../../../fragments/Notifications/browser';

export async function loadFragmentsAPI(context: IApplicationContext) {
  await Promise.all([
    loadFavoritesFragmentAPI(context),
    loadNotificationsFragmentAPI(context),
    loadComplaintsFragmentAPI(context),
    loadNotesFragmentApi(context),
  ]);
}
