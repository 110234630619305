import * as React from 'react';

import { SkeletonRect } from '../../../../../../packages/SkeletonLoader';
import { Section } from '../../Section';

export function CarouselLoader() {
  return (
    <Section>
      <SkeletonRect height={320} borderRadius={0} />
    </Section>
  );
}
