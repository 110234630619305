import { IApplicationContext } from '../../../shared/types/applicationContext';

export async function loadFragmentAPI(context: IApplicationContext) {
  const { runFragmentAPI } = await import('../runFragmentAPI');

  await runFragmentAPI(context);
}

export function loadLazyFavoritesAssignWidgetFragment(context: IApplicationContext) {
  return async () => {
    const { runFavoritesAssignWidget } = await import('../runFavoritesAssignWidget');

    return runFavoritesAssignWidget(context);
  };
}
