import { ca } from '@cian/analytics';

export function trackDescriptionOpen() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'card',
    action: 'read_all',
    label: 'description',
    useLastProducts: true,
  });
}
