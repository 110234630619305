import loadable from '@loadable/component';
import * as React from 'react';
import { hydrateRoot, createRoot } from 'react-dom/client';

import { createContext } from './createContext';
import * as CardApp from '../../../../Card/CardEntry/browser';
import * as SerpApp from '../../../../Serp/browser';
import { ErrorPage } from '../../../../packages/ErrorPage';
import { TPageType } from '../../../shared/types';
import { Entry } from '../../containers/Entry';
import { consumeSerp } from '../consumeSerp';
import { loadFragmentsAPI } from '../fragmentsAPI';

export async function runApp() {
  const context = createContext();
  const { config, logger, telemetry } = context;

  consumeSerp();

  const projectName = config.getStrict<string>('projectName');

  const rootElement = document.getElementById(projectName);

  if (!rootElement) {
    throw new Error(`Unable to find element #${projectName}`);
  }

  const fragmentsAPIPromise = loadFragmentsAPI(context);

  const pageType = config.getStrict<TPageType>('mobile-search-frontend.entry.pageType');

  let Card: React.ComponentType<CardApp.IOpenCardRequestProps>;
  let Serp: React.ComponentType;
  let ResultsForMap: React.ComponentType;

  try {
    switch (pageType) {
      case 'serp':
        Serp = await SerpApp.loadSerp(context);
        Card = loadable(CardApp.lazyLoad(context), {
          fallback: <CardApp.ApplicationLoader />,
        });
        ResultsForMap = loadable(SerpApp.lazyLoadResultsForMap(context), {
          fallback: SerpApp.fallbackSerp(),
        });
        break;
      case 'resultsForMap':
        ResultsForMap = await SerpApp.loadResultsForMap(context);
        Serp = loadable(SerpApp.lazyLoad(context), {
          fallback: SerpApp.fallbackSerp(),
        });
        Card = loadable(CardApp.lazyLoad(context), {
          fallback: <CardApp.ApplicationLoader />,
        });
        break;
      default:
        Card = await CardApp.load(context);
        Serp = loadable(SerpApp.lazyLoad(context), {
          fallback: SerpApp.fallbackSerp(),
        });
        ResultsForMap = loadable(SerpApp.lazyLoadResultsForMap(context), {
          fallback: SerpApp.fallbackSerp(),
        });
    }

    await fragmentsAPIPromise;

    hydrateRoot(rootElement, <Entry context={context} pages={{ Card, Serp, ResultsForMap }} />);
  } catch (e) {
    telemetry.increment('entry.fragments.loading.fail');
    logger.error(e);

    const root = createRoot(rootElement);
    root.render(<ErrorPage />);
  }
}
