import { IApplicationContext } from '../../../shared/types/application';

export async function loadSerp(context: IApplicationContext) {
  const { runSerpApp } = await import('../runApp');
  const mdl = await runSerpApp(context);

  return mdl.default;
}

export function lazyLoad(context: IApplicationContext) {
  return async () => {
    const { runSerpAppLazy } = await import('../runApp');
    const mdl = await runSerpAppLazy(context);

    return mdl;
  };
}

export async function loadResultsForMap(context: IApplicationContext) {
  const { runResultsForMapApp } = await import('../runApp');
  const mdl = await runResultsForMapApp(context);

  return mdl.default;
}

export function lazyLoadResultsForMap(context: IApplicationContext) {
  return async () => {
    const { runResultsForMapAppLazy } = await import('../runApp');
    const mdl = await runResultsForMapAppLazy(context);

    return mdl;
  };
}
