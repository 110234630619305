import { ErrorLogComponent } from '@cian/error-log-component';
import * as React from 'react';
import { Router } from 'react-router-dom';

import { IsFirstPathRenderProvider } from './IsFirstPathRenderProvider';
import { ErrorPage } from '../../../../packages/ErrorPage';
import { IApplicationContext } from '../../../shared/types';
import { getHistory } from '../../tools/history';
import { PageSwitch, IPageComponents } from '../PageSwitch';

export interface IEntryProps {
  pages: IPageComponents;
  context: IApplicationContext;
}

export function Entry({ pages, context: { logger, telemetry } }: IEntryProps) {
  const handleIncrementTelemetry = React.useCallback(() => {
    telemetry.increment('entry.fragments.render.fail');
  }, [telemetry]);

  return (
    <ErrorLogComponent logger={logger} errorStub={<ErrorPage />} onError={handleIncrementTelemetry}>
      <Router history={getHistory()}>
        <IsFirstPathRenderProvider
          render={(isFirstPathRender: boolean) => <PageSwitch pages={pages} isFirstPathRender={isFirstPathRender} />}
        />
      </Router>
    </ErrorLogComponent>
  );
}
