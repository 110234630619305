import * as React from 'react';

import { CollapsedButton } from './CollapsedButton';

interface ICollapsedBlockButtonProps
  extends JSX.LibraryManagedAttributes<typeof CollapsedButton, React.ComponentPropsWithoutRef<typeof CollapsedButton>> {
  toggle(): void;
}

export function CollapsedBlockButton({ toggle, ...buttonProps }: ICollapsedBlockButtonProps) {
  const closedContent = buttonProps.closedContent || 'Читать полностью';

  return <CollapsedButton {...buttonProps} onClick={toggle} closedContent={closedContent} />;
}
