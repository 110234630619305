import { ArticleHeading2, ArticleParagraph1 } from '@cian/ui-kit';
import { Image } from '@cian/ui-kit/image';
import * as React from 'react';

import * as styles from './ErrorPage.css';
import img from './assets/error.png';

export function ErrorPage() {
  return (
    <div className={styles['page']}>
      <div className={styles['message']}>
        <Image width={100} height={100} src={img} />
        <div className={styles['warning']}>
          <div className={styles['heading']}>
            <ArticleHeading2 textAlign="center">Страница не загрузилась</ArticleHeading2>
          </div>
          <ArticleParagraph1 color="gray60_100" textAlign="center">
            Попробуйте ещё раз,
            <br />
            уже должно работать
          </ArticleParagraph1>
        </div>
      </div>
    </div>
  );
}
