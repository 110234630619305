import { getHistory } from '../../../tools/history';
import { TOpenCardEvent } from '../types/mobileSearchEvent';

export function openCardEventHandler(event: TOpenCardEvent) {
  const { dealType, offerType, cianId, pageType, parentOrigin } = event.value;
  const url = `/${dealType}/${offerType}/${cianId}/`;

  if (pageType === 'resultsForMap') {
    const prefix = parentOrigin || '';
    window.open(`${prefix}${url}`, '_blank');

    return;
  }

  getHistory().push(url, event.value);
}
