import classNames from 'classnames';
import * as React from 'react';

import * as styles from './OfferDetailsInfo.css';
import { trackDescriptionOpen } from './analytics';
import { CollapsedBlock } from '../../../../../packages/CollapsedBlock';
import { TAuthor } from '../../types/author';
import { ILabelValueItem } from '../../types/labelValue';
import { IAmenities } from '../../types/offer/amenities';
import { Amenities, GeneralAmenities } from '../Amenities';
import { LabelValueList } from '../LabelValueList';
import { Section } from '../Section';
import { Title } from '../Title';
import { ZalogDesclaimer } from '../ZalogDesclaimer';

export interface IOfferDetailsInfoProps {
  additionalInfo: ILabelValueItem[];
  generalAmenitiesTitle: string;
  amenities: IAmenities;
  demolitionInvolved: boolean;
  description: string[];
  generalInfo: ILabelValueItem[];
  author?: TAuthor | null;
  isAuction: boolean;
}

/**
 * TODO: отрефачить компонент - разнести по контейнерам:
 * // src/Card/shared/containers/OfferDetailsContainer/OfferDetailsContainer.tsx:
 *
 * <Section paper data-name="OfferDetailsInfo">
 *   <OfferDetailsDescriptionContainer>
 *   <OfferDetailsRenovationContainer>
 *   <OfferDetailsCommonInfoContainer>
 *   <OfferDetailsAdditionalInfoContainer>
 *   <OfferDetailsAmenitiesContainer>
 * </Section>
 *
 * - каждый контейнер сам потребляет данные из стора.
 */
export class OfferDetailsInfo extends React.Component<React.PropsWithChildren<IOfferDetailsInfoProps>> {
  private handleDescriptionChangeOpen = (isOpen: boolean) => {
    if (isOpen) {
      trackDescriptionOpen();
    }
  };

  public render() {
    const {
      children,
      additionalInfo,
      generalAmenitiesTitle,
      amenities,
      demolitionInvolved,
      description,
      generalInfo,
      author,
      isAuction,
    } = this.props;

    return (
      <Section paper data-name="OfferDetailsInfo">
        <div className={styles['content-block']}>
          <Title>Описание</Title>
          <CollapsedBlock collapsedHeight={96} onChangeOpen={this.handleDescriptionChangeOpen}>
            <div>
              {description.map((block, index) => (
                <React.Fragment key={index}>
                  {block}
                  <br />
                </React.Fragment>
              ))}
            </div>
          </CollapsedBlock>
        </div>

        {(author?.bank || isAuction) && (
          <div className={classNames(styles['content-block'], styles['special-background'])}>
            <ZalogDesclaimer
              title="Залоговая недвижимость"
              description="Это имущество, которое переходит в собственность банка, если заемщик не исполняет обязательства по договору
              займа перед банком"
            />
          </div>
        )}

        {isAuction && (
          <div className={classNames(styles['content-block'], styles['special-background'])}>
            <ZalogDesclaimer
              title="Реализуется через аукцион"
              description="Публичная продажа недвижимости,
                при которой продаваемая недвижимость приобретается лицом, предложившим
                за нее наивысшую цену"
            />
          </div>
        )}

        {children}

        {demolitionInvolved && (
          <div className={classNames(styles['content-block'], styles['content-block--renovation'])}>
            Дом включен в{' '}
            <a href="https://www.mos.ru/city/projects/renovation" target="_blank" rel="noopener noreferrer nofollow">
              программу реновации
            </a>
          </div>
        )}

        {generalInfo.length > 0 && (
          <div className={styles['content-block']}>
            <Title>Общая информация</Title>
            <LabelValueList items={generalInfo} />
          </div>
        )}

        {additionalInfo.length > 0 && (
          <div className={styles['content-block']}>
            <LabelValueList items={additionalInfo} />
          </div>
        )}

        <GeneralAmenities
          title={generalAmenitiesTitle}
          amenities={amenities.general}
          className={styles['content-block']}
        />
        <Amenities amenities={amenities.house} className={styles['content-block']} />
        <Amenities amenities={amenities.stead} className={styles['content-block']} />
        <Amenities amenities={amenities.allowed} className={styles['content-block']} />
        <Amenities amenities={amenities.tenants} className={styles['content-block']} />
      </Section>
    );
  }
}
