import { useUpdateEffect } from '@cian/react-utils';
import { useHistory } from 'react-router';

export function useRouterScrollEffect() {
  const { action, location } = useHistory();

  useUpdateEffect(() => {
    /**
     * * PUSH - Это всегда переходы вперед/назад
     *     Точно никогда не надо скроллить
     *
     * * REPLACE - сценарии бывают разными
     *     Если понадобится скролл, то его нужно производить в том же месте что и сам history.replace()
     */
    if (action === 'PUSH') {
      window.scrollTo(0, 0);
    }
  }, [action, location]);
}
