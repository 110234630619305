import { createConsumer, createSubscriber } from '@cian/events-log';

import { openCardEventHandler } from './eventHandlers/openCardEventHandler';
import { sortOffersEventHandler } from './eventHandlers/sortOffersEventHandler';
import { TMobileSearchEvent, EMobileSearchEventType } from './types/mobileSearchEvent';

export function consumeSerp() {
  const consumer = createConsumer({ topic: 'mobile-search-frontend' });
  // TODO: обновить в библиотеке тип subscriber
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const subscriber = createSubscriber(mobileSearchEventHandler as any);

  consumer.subscribe(subscriber);
}

function mobileSearchEventHandler(event: TMobileSearchEvent) {
  switch (event.type) {
    case EMobileSearchEventType.OpenCard:
      openCardEventHandler(event);
      break;

    case EMobileSearchEventType.SortOffers:
      sortOffersEventHandler(event);
      break;
  }
}
