import * as React from 'react';

import * as styles from './PageLayout.css';

export function PageLayout(props: React.PropsWithChildren<{ dataMark?: string }>) {
  return (
    <div data-mark={props.dataMark} className={styles['container']}>
      {props.children}
    </div>
  );
}
