import * as React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';

import { extractCardRequest } from './extractCardRequest';
import { useRouterScrollEffect } from './useRouterScrollEffect';
import { IOpenCardRequestProps } from '../../../../Card/CardEntry/browser';
import { ROUTES } from '../../../shared/constants/router';
import { ICardRouteParams } from '../../../shared/types/router';

interface IPageProps {
  isFirstPathRender?: boolean;
  children?: React.ReactNode;
}

export interface IPageComponents {
  Card: React.ComponentType<IOpenCardRequestProps>;
  Serp: React.ComponentType<IPageProps> | React.ExoticComponent<IPageProps>;
  ResultsForMap: React.ComponentType<IPageProps> | React.ExoticComponent<IPageProps>;
}

interface IPageSwitchProps {
  pages: IPageComponents;
  isFirstPathRender: boolean;
}

export function PageSwitch(props: IPageSwitchProps) {
  const { isFirstPathRender } = props;
  const { Card, Serp, ResultsForMap } = props.pages;

  useRouterScrollEffect();

  return (
    <Switch>
      <Route
        path={ROUTES.card.path}
        render={props => (
          <Card
            request={extractCardRequest(props as unknown as RouteComponentProps<ICardRouteParams>)}
            isFirstPathRender={isFirstPathRender}
          />
        )}
      />
      <Route path={ROUTES.serp.path} render={props => <Serp {...props} isFirstPathRender={isFirstPathRender} />} />
      <Route
        path={ROUTES.resultsForMap.path}
        render={props => <ResultsForMap {...props} isFirstPathRender={isFirstPathRender} />}
      />
    </Switch>
  );
}
