import * as React from 'react';

import { CarouselLoader } from './CarouselLoader';
import { GeoLoader } from './GeoLoader';
import { MainInfoLoader } from './MainInfoLoader';
import { OfferDetailsInfoLoader } from '../OfferDetailsInfo';
import { PageLayout } from '../PageLayout';

export function ApplicationLoader() {
  return (
    <PageLayout>
      <CarouselLoader />
      <MainInfoLoader />
      <GeoLoader />
      <OfferDetailsInfoLoader />
    </PageLayout>
  );
}
