import { TDeviceType } from '@cian/ui-kit/types';

import { ECategory } from '../../Card/LegacyCard/shared/types/offer';
import { TPlatform } from '../../Card/LegacyCard/shared/types/platform';

export enum EOpenMobileCardFrontendRequestType {
  Empty = 'Empty',
  Partial = 'Partial',
}

export interface IExperiment {
  experimentName: string;
  groupName?: string | null;
}

export interface IOpenMobileCardFrontendRequestRequiredData {
  /** Тип сделки **/
  dealType: EDealType;
  /** Cian ID объявления **/
  cianId: number;
  /** Тип объявления **/
  offerType: EOfferType;
}

export interface IOpenMobileCardFrontendRequest {
  requestType: EOpenMobileCardFrontendRequestType;
}

export interface IOpenMobileCardFrontendRequestEmpty
  extends IOpenMobileCardFrontendRequest,
    IOpenMobileCardFrontendRequestRequiredData {
  requestType: EOpenMobileCardFrontendRequestType.Empty;
}

export interface IOpenMobileCardFrontendRequestPartialData extends IOpenMobileCardFrontendRequestRequiredData {
  /** Информация о корпусе новостройки/ЖК **/
  building: IBuilding | null;
  /** Realty ID объявления **/
  id: number;
  /** Поддомен **/
  subdomain?: string | null;
  media: IMedia[];
  /** Лейблы на фото **/
  photoLabels?: IPhotoLabel[] | null;
  icons: TMetaIcon[];
  price: IPrice;
  features: string[];
  geo: IGeo;
  user: TUser;
  deviceType: TDeviceType;
  platform: TPlatform;
  /** Сделка в аренде **/
  offerInDeal?: boolean | null;
  /** Версия сделки в аренде*/
  dealRentVersion?: EDealRentVersion | null;
  experiments: IExperiment[];
  testMode?: boolean;
  identicalOffers?: IIdenticalOffers | null;
  isOfferInFavorites: boolean;
  pageType: TPageType | null;
  parentOrigin: string | null;
  author: IAuthor | null;
  calltracking: ICalltracking | null;
  mortgageCalculatorSettings: { rate: number };
  dealRentMonetization?: EDealRentMonetization | null;
  category: ECategory;
  isCalltrackingEnabled: boolean;
  isDealRentOffline?: boolean | null;
}

export interface IOpenMobileCardFrontendRequestPartial
  extends IOpenMobileCardFrontendRequest,
    IOpenMobileCardFrontendRequestPartialData {
  requestType: EOpenMobileCardFrontendRequestType.Partial;
}

export type TOpenMobileCardFrontendRequest =
  | IOpenMobileCardFrontendRequestEmpty
  | IOpenMobileCardFrontendRequestPartial;

export enum EOfferType {
  /** коммерческая **/
  Commercial = 'commercial',
  /** квартиры **/
  Flat = 'flat',
  /** новостройки **/
  Newobject = 'newobject',
  /** загородка **/
  Suburban = 'suburban',
}

export enum EDealType {
  /** Аренда **/
  Rent = 'rent',
  /** Продажа **/
  Sale = 'sale',
}

export interface IMedia {
  previewUrl: string;
  type: EType;
  url: string;
}

export enum EType {
  Layout = 'layout',
  Photo = 'photo',
  Video = 'video',
}

export enum EDealRentVersion {
  V1 = 'v1',
  V2 = 'v2',
}

interface IPhotoLabel {
  color: TPhotolabelColor;
  name: string;
}

interface IIdenticalOffers {
  title: string;
  url: string;
}

interface ICalltracking {
  comagicId: number | null;
  newbuildingId?: number | null;
}

interface IAuthor {
  fromDeveloper?: string | null;
  isFromLeadFactory?: boolean | null;
  bank?: string | null;
}

export type TPageType = 'serp' | 'card' | 'resultsForMap';

export type TPhotolabelColor = 'blue' | 'green' | 'orange' | 'white' | 'grey';

export type TMetaIcon = 'auction' | 'builder' | 'colorized' | 'fairPlay' | 'payed' | 'premium' | 'pro' | 'top3';

export interface IPrice {
  currency: TCurrency;
  value: number;
  period?: TPeriod | null;
}

export type TCurrency = 'eur' | 'rur' | 'usd';

export type TPeriod = 'day' | 'month';

export interface IGeo {
  regionId: number;
  address: string;
  undergrounds: IUnderground[];
  highways: IHighways[];
  title?: IGeoTitle | null;
}

export interface IUnderground {
  id: number;
  name: string;
  underConstruction: boolean;
  lineColor?: string | null;
  time?: string | null;
  transportType?: ETransportType | null;
}

export interface IHighways {
  id: number;
  name: string;
  distance?: string | null;
}

export interface ICoordinates {
  lat: number;
  lng: number;
}

export enum ETransportType {
  Transport = 'transport',
  Walk = 'walk',
}

export interface IGeoTitle {
  name?: string | null;
  link?: string | null;
}

export interface IUserFavoritesCount {
  total: number;
}

export interface IUserUnauthenticated {
  isAuthenticated: false;
  favoritesCount: IUserFavoritesCount;
}

export interface IUserAuthenticated {
  isAuthenticated: true;
  email: string | null;
  phones: string[] | null;
  userId: number | null;
  firstName: string | null;
  lastName: string | null;
  cianId: number | null;
  displayName: string | null;
  webimHash: string | null;
  favoritesCount: IUserFavoritesCount;
  isAgent: boolean;
  isBuilder: boolean;
}

export type TUser = IUserUnauthenticated | IUserAuthenticated;

export interface IBuilding {
  /** Строка о сдаче дома **/
  deadline?: string | null;
  /** Строка о доме **/
  houseName?: string | null;
}

export enum EDealRentMonetization {
  /** Комиссию платит собственник **/
  FeeByHomeowner = 'feeByHomeowner',
  /** Комиссию платит арендатор **/
  FeeByRenter = 'feeByRenter',
  FeeIncludedInOfferPrice = 'feeIncludedInOfferPrice',
}
