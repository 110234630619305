import * as React from 'react';

import * as styles from './ZalogDesclaimer.css';

interface IZalogDesclaimerProps {
  title: string;
  description: string;
}

export const ZalogDesclaimer: React.FC<IZalogDesclaimerProps> = ({ title, description }) => {
  return (
    <>
      <div className={styles['header']}>{title}</div>
      <div>{description}</div>
    </>
  );
};

ZalogDesclaimer.displayName = 'ZalogDesclaimer';
