import * as React from 'react';

import { BaseInfoLoader } from '../../BaseInfo/BaseInfoLoader';
import { FavoritesLoader } from '../../Favorites/FavoritesLoader';
import { MainInfoLayout } from '../../MainInfoLayout';
import { MetaLoader } from '../../Meta/MetaLoader';
import { MetaLayout } from '../../MetaLayout';

export function MainInfoLoader() {
  return (
    <MainInfoLayout paper={false}>
      <MetaLayout left={<MetaLoader />} right={<FavoritesLoader />} />
      <BaseInfoLoader />
    </MainInfoLayout>
  );
}
