import { NotFoundError } from '@cian/peperrors/shared';
import { createBrowserHistory, History } from 'history';

let history: History;

export function initHistory() {
  if (history) {
    return history;
  }

  history = createBrowserHistory();

  return history;
}

export function getHistory() {
  if (!history) {
    throw new NotFoundError({
      message: 'History must be initialized before accessing it. Forgot to call init() before accessing history?',
      domain: 'Entry/tools/history#getHistory()',
    });
  }

  return history;
}
