import * as React from 'react';

import * as styles from './MetaLayout.css';

interface IProps {
  left: React.ReactNode;
  right: React.ReactNode;
}

export function MetaLayout(props: IProps) {
  const { left, right } = props;

  return (
    <div className={styles['container']}>
      <div className={styles['left']}>{left}</div>
      <div className={styles['right']}>{right}</div>
    </div>
  );
}
