import * as React from 'react';

import { SkeletonRect, SkeletonTextRect } from '../../../../../../packages/SkeletonLoader';
import { Section } from '../../Section';

export function GeoLoader() {
  return (
    <Section>
      <div style={{ padding: 15 }}>
        <SkeletonTextRect width={120} height={19} fontSize={16} style={{ marginBottom: 5 }} />

        <SkeletonTextRect width="90%" height={22} fontSize={16} style={{ marginBottom: 5 }} />

        <SkeletonTextRect width={180} height={20} fontSize={16} />
        <SkeletonTextRect width={160} height={20} fontSize={16} />
        <SkeletonTextRect width={170} height={20} fontSize={16} />
      </div>
      <SkeletonRect height={188} borderRadius={0} />
    </Section>
  );
}
