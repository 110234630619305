import * as React from 'react';

import { SkeletonTextRect } from '../../../../../packages/SkeletonLoader';

interface IMetaLoaderProps {
  animated?: boolean;
}

export function MetaLoader({ animated }: IMetaLoaderProps) {
  return <SkeletonTextRect animated={animated} height={20} width={220} />;
}
