import { RouteComponentProps } from 'react-router';

import {
  IOpenMobileCardFrontendRequestPartialData,
  TOpenMobileCardFrontendRequest,
  EOpenMobileCardFrontendRequestType,
} from '../../../../../Repositories/mobile-card-frontend';
import { ICardRouteParams } from '../../../../shared/types/router';

export function extractCardRequest({
  location,
  match,
}: RouteComponentProps<ICardRouteParams>): TOpenMobileCardFrontendRequest {
  const state = location.state as IOpenMobileCardFrontendRequestPartialData | void;
  const { params } = match;

  if (state) {
    return {
      ...state,
      requestType: EOpenMobileCardFrontendRequestType.Partial,
    };
  }

  return {
    requestType: EOpenMobileCardFrontendRequestType.Empty,
    cianId: parseInt(params.cianId, 10),
    offerType: params.offerType,
    dealType: params.dealType,
  };
}
