import { IEvent } from '@cian/events-log';

import { IOpenMobileCardFrontendRequestPartialData } from '../../../../../Repositories/mobile-card-frontend';

interface ISortOffersEventPayload {
  path: string;
}

interface IMobileSearchEvent<T extends string, V> extends IEvent<V> {
  type: T;
}

export enum EMobileSearchEventType {
  OpenCard = 'OPEN_CARD',
  SortOffers = 'SORT_OFFERS',
}

export type TOpenCardEvent = IMobileSearchEvent<
  EMobileSearchEventType.OpenCard,
  IOpenMobileCardFrontendRequestPartialData
>;
export type TSortOffersEvent = IMobileSearchEvent<EMobileSearchEventType.SortOffers, ISortOffersEventPayload>;

export type TMobileSearchEvent = TOpenCardEvent | TSortOffersEvent;
