import * as React from 'react';

import * as styles from './MainInfoLayout.css';
import { Section } from '../Section';

interface IMainInfoLayoutProps {
  paper?: boolean;
  children: React.ReactNode;
}

export function MainInfoLayout({ paper = true, children }: IMainInfoLayoutProps) {
  return (
    <Section paper={paper} className={styles['main-info-section']} style={{ borderTop: 'none' }}>
      {children}
    </Section>
  );
}
