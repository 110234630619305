import { IApplicationContext } from '../../../shared/types/applicationContext';

export async function loadFragmentAPI(context: IApplicationContext) {
  const { runFragmentAPI } = await import('../runFragmentAPI');

  await runFragmentAPI(context);
}

export function loadLazyNotesFragment(context: IApplicationContext) {
  return async () => {
    const { runNotesFragment } = await import('../runNotesFragment');

    return runNotesFragment(context);
  };
}
