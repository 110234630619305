/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { withRouter } from 'react-router-dom';

interface IIsFirstPathRenderProviderProps {
  render(isFirstPathRender: boolean): React.ReactElement;
}

let isFirstRender = true;

class IsFirstPathRenderProviderComponent extends React.Component<IIsFirstPathRenderProviderProps> {
  public componentDidMount() {
    isFirstRender = false;
  }

  public render() {
    return this.props.render(isFirstRender);
  }
}

export const IsFirstPathRenderProvider = withRouter(
  IsFirstPathRenderProviderComponent as any,
) as any as typeof IsFirstPathRenderProviderComponent;
