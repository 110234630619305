import cx from 'classnames';
import * as React from 'react';

import * as styles from '../styles.css';

interface ISectionProps {
  className?: string;
  children: React.ReactNode;
  padded?: boolean;
  dataMark?: string;
}

export const Section = React.forwardRef<HTMLElement, ISectionProps>(
  ({ className, children, padded = true, dataMark, ...restProps }, ref) => {
    return (
      <section
        {...restProps}
        ref={ref}
        data-mark={dataMark}
        className={cx(styles['section'], padded && styles['padded-content'], className)}
      >
        {children}
      </section>
    );
  },
);

Section.displayName = 'Section';
