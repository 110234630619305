import { IApplicationContext } from '../../../shared/types/applicationContext';

export async function loadFragmentAPI(context: IApplicationContext) {
  const { runFragmentAPI } = await import('../runFragmentAPI');

  await runFragmentAPI(context);
}

export function loadLazyNotificationsFragment(context: IApplicationContext) {
  return async () => {
    const { runApp } = await import('../runApp');

    return runApp(context);
  };
}
