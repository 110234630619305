import classNames from 'classnames';
import * as React from 'react';

import * as styles from './Section.css';

interface ISectionProps<T extends keyof JSX.IntrinsicElements> {
  tag?: T;
  paper?: boolean;
}

type TSectionProps<T extends keyof JSX.IntrinsicElements> = ISectionProps<T> & JSX.IntrinsicElements[T];

export class Section<T extends keyof JSX.IntrinsicElements = 'section'> extends React.PureComponent<TSectionProps<T>> {
  public static defaultProps = {
    tag: 'section',
  };

  public render() {
    const { tag: Tag = 'section', paper, ...sharedProps } = this.props;

    return (
      <Tag
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        {...(sharedProps as any)}
        className={classNames(styles['container'], paper && styles['paper'], sharedProps.className)}
      />
    );
  }
}
