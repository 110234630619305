import classNames from 'classnames';
import * as React from 'react';

import * as styles from './SkeletonRect.css';

type TDivProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export interface ISkeletonRectProps extends Omit<TDivProps, 'children'> {
  height?: number | string;
  width?: number | string;
  borderRadius?: number;
  animated?: boolean;
}

export function SkeletonRect({ height, width, borderRadius, animated = true, ...passedProps }: ISkeletonRectProps) {
  return (
    <div
      {...passedProps}
      style={{
        ...passedProps.style,
      }}
      className={classNames(passedProps.className, styles['root'], animated && styles['animated'])}
    >
      <div
        className={styles['fill']}
        style={{
          height,
          width,
          borderRadius,
        }}
      />
    </div>
  );
}
