import * as React from 'react';

import { SkeletonRect, ISkeletonRectProps } from '../SkeletonRect';

export interface ISkeletonTextRectProps extends ISkeletonRectProps {
  height?: number;
  width: number | string;
  fontSize?: number;
}

export function SkeletonTextRect({ height = 20, fontSize = 16, ...passedProps }: ISkeletonTextRectProps) {
  const vPadding = height - fontSize;

  return (
    <SkeletonRect
      {...passedProps}
      height={fontSize}
      style={{
        ...passedProps.style,
        paddingTop: Math.ceil(vPadding * 0.6),
        paddingBottom: Math.floor(vPadding * 0.4),
      }}
    />
  );
}
