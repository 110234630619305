import * as React from 'react';

import { AmenityItem } from './AmenityItem';
import * as styles from './styles.css';
import { IAmenitiesProps } from './types';

export const AmenitiesComponent: React.FC<IAmenitiesProps> = ({ amenities }) => (
  <ul className={styles['amenities-container']}>
    {amenities.map(item => (
      <AmenityItem key={item.amenity} {...item} />
    ))}
  </ul>
);

export const Amenities: React.FC<IAmenitiesProps> = props => {
  if (props.amenities.length === 0) {
    return null;
  }

  return (
    <div className={props.className}>
      <AmenitiesComponent {...props} />
    </div>
  );
};
