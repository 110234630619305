import * as React from 'react';

import { SkeletonTextRect } from '../../../../../packages/SkeletonLoader';
import { Section } from '../Section';

export function OfferDetailsInfoLoader() {
  return (
    <Section style={{ padding: 15 }}>
      <SkeletonTextRect width={140} height={20} fontSize={18} style={{ marginBottom: 15 }} />

      <div style={{ marginBottom: 4 }}>
        <SkeletonTextRect width="100%" height={20} fontSize={14} />
        <SkeletonTextRect width="90%" height={20} fontSize={14} />
        <SkeletonTextRect width="70%" height={20} fontSize={14} />
      </div>
    </Section>
  );
}
