import { Spinner } from '@cian/ui-kit/loader';
import * as React from 'react';

import * as styles from './ApplicationLoader.css';

export function ApplicationLoader() {
  return (
    <div className={styles['container']}>
      <Spinner size={32} />
    </div>
  );
}
