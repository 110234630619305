import * as React from 'react';

import { SkeletonTextRect } from '../../../../../packages/SkeletonLoader';

export function BaseInfoLoader() {
  return (
    <>
      <SkeletonTextRect width={140} height={28} fontSize={22} style={{ marginBottom: 12 }} />

      <SkeletonTextRect width="90%" height={23} fontSize={16} />

      <div style={{ marginBottom: 4 }}>
        <SkeletonTextRect width="90%" height={23} fontSize={16} />
        <SkeletonTextRect width="80%" height={18} fontSize={14} />
        <SkeletonTextRect width="60%" height={18} fontSize={14} />
      </div>

      <SkeletonTextRect width={220} height={18} fontSize={14} style={{ marginBottom: 4 }} />

      <SkeletonTextRect width={180} height={20} fontSize={16} style={{ marginBottom: 4 }} />

      <SkeletonTextRect width={120} height={17} fontSize={14} />
    </>
  );
}
