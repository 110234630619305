import { IApplicationContext } from '../../../shared/types/applicationContext';

export async function loadFragmentAPI(context: IApplicationContext) {
  const { runFragmentAPI } = await import('../runFragmentAPI');

  await runFragmentAPI(context);
}

export function loadLazyComplaintsFragment(context: IApplicationContext) {
  return async () => {
    const { runComplaintsFragment } = await import('../runComplaintsFragment');

    return runComplaintsFragment(context);
  };
}
