import classNames from 'classnames';
import * as React from 'react';

import * as styles from './CollapsedButton.css';

type TButtonProps = Omit<
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  'type'
>;

export interface ICollapsedButtonProps extends TButtonProps {
  opened: boolean;
  openedContent?: React.ReactNode;
  closedContent?: React.ReactNode;
}

export const CollapsedButton: React.FC<ICollapsedButtonProps> = props => {
  const { opened, openedContent = 'Свернуть', closedContent = 'Показать полностью', ...buttonProps } = props;

  return (
    <button
      {...buttonProps}
      type="button"
      className={classNames(styles['toggle-btn'], opened && styles['toggle-btn--close'], buttonProps.className)}
    >
      {opened ? openedContent : closedContent}
    </button>
  );
};
