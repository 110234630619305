import * as React from 'react';

import { AmenitiesComponent } from './Amenities';
import * as styles from './styles.css';
import { IGeneralAmenitiesProps } from './types';
import { CollapsedBlock } from '../../../../../packages/CollapsedBlock';

export const GeneralAmenitiesComponent: React.FC<IGeneralAmenitiesProps> = ({ amenities, title, className }) => (
  <div className={className}>
    <h2 className={styles['header']}>{title}</h2>

    <CollapsedBlock collapsedHeight={156}>
      <AmenitiesComponent amenities={amenities} />
    </CollapsedBlock>
  </div>
);

export const GeneralAmenities: React.FC<IGeneralAmenitiesProps> = props => {
  if (props.amenities.length === 0) {
    return null;
  }

  return <GeneralAmenitiesComponent {...props} />;
};
