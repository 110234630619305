import * as React from 'react';

interface IWithChangeOrienatationProps {
  children: JSX.Element;
  handler(): void;
}

export const WithChangeOrientation = (props: IWithChangeOrienatationProps) => {
  const handler = React.useCallback(() => {
    props.handler();
  }, [props]);

  React.useEffect(() => {
    const mediaQuery = window.matchMedia('(orientation: portrait)');

    mediaQuery.addListener(handler);

    return () => mediaQuery.removeListener(handler);
  }, [handler]);

  React.useEffect(() => {
    handler();
  }, [handler]);

  return props.children;
};
